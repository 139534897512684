.search-container {
  margin-top: 10px;
  display: flex;
  align-items: center;

  border-radius: 12px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  color: black;
  max-width: 790px;
  margin: 0 auto;
}

.search-container.tags {
  margin: 0;
}

.search-container:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.search-container .search-icon {
  margin-left: calc(1em);
}

.search-container .search-input {
  font: inherit;
  color: currentcolor;
  padding: 15px 5px;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 100%;
  width: 100%;
  margin: 0px;
  outline: none;
}

.btn-search {
  background: #5768ff;
  border: none;
  padding: 15px 32px;
  box-shadow: 0px 5px 30px rgba(66, 130, 255, 0.2);
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.btn-search.btn-search-mobile {
  display: none;
}

@media (max-width: 767px) {
  .search-container .btn-search.btn-search-desktop {
    display: none;
  }

  .btn-search.btn-search-mobile {
    display: block;
    margin-top: 15px;
    width: 100%;
  }
}
