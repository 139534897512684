.hero-section {
  max-width: 1536px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: #ffffff;
  padding: 64px;
}

.hero-section .hero-title {
  font-size: 54px;
  font-weight: bold;
  margin: 0;
}

.hero-section .hero-text {
  font-size: 16px;
  font-weight: normal;
  opacity: 0.7;
  margin: 0 0 30px 0;
  z-index: 0;
}

.hero-section .search-section {
  width: 100%;
}

.hero-section .hero-tags {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.hero-section .hero-tags span {
  background-color: #ffffff10;
  padding: 12px 24px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

@media (max-width: 767px) {
  .hero-section {
    padding: 32px;
    margin: 0 auto;
  }

  .hero-section .hero-title,
  .hero-section .hero-text {
    text-align: center;
  }

  .hero-section .hero-tags {
    display: none;
  }
}
