.filter-section {
  max-width: 1420px;
  margin: 0 auto;
  padding: 24px 64px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .filter-section {
    padding: 24px 32px;
  }

  .filter-section .filter-switch {
    display: none;
  }
}
