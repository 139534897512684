.switch-container {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
}

.switch {
  position: relative;
  width: 250px;
  height: 45px;
  border-radius: 25px;
}

.switch input {
  appearance: none;
  width: 250px;
  height: 45px;
  border-radius: 25px;
  background: #363b48;
  outline: none;
  box-shadow: 1px 6px 11px #000000;
}

.switch input::before,
.switch input::after {
  z-index: 2;
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
  font-weight: 500;
}

.switch input::before {
  content: url("./icons/top-icon.png") "  MOST ACTIVE";
  font-size: 16px;
  left: 20px;
}

.switch input::after {
  content: url("./icons/new-icon.png") "  BUMP";
  font-size: 16px;
  right: 15px;
}

.switch input:checked {
  background: #363b48;
}

.switch label {
  z-index: 1;
  position: absolute;
  top: 3px;
  bottom: -3px;
  border-radius: 30px;
}

.switch input {
  transition: 0.25s;
}

.switch input:checked:before,
.switch input:checked:after {
  color: #ffffff;
  transition: color 0.5s;
}

.switch input:checked + label {
  left: 3px;
  right: 90px;
  background: #5768ff;
  transition: left 0.5s, right 0.4s 0.2s;
}

.switch input:not(:checked) {
  background: #363b48;
  transition: background 0.4s;
}

.switch input:not(:checked)::before {
  color: #ffffff;
  transition: color 0.5s;
}

.switch input:not(:checked)::after {
  color: #ffffff;
  transition: color 0.5s 0.2s;
}

.switch input:not(:checked) + label {
  left: 150px;
  right: -3px;
  background: #5768ff;
  transition: left 0.4s 0.2s, right 0.5s, background 0.35s;
}

/*  End Toggle Switch  */
