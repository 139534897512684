.preview-card {
  background-color: white;
  border-radius: 24px;
  max-width: 410px;
  flex-grow: 1;
  /* flex-shrink: 1; */
  overflow: hidden;
  padding: 20px;
  /* width: 100%; */
  /* filter: drop-shadow(0 4px 3px rgba(87, 104, 255, 0.684)) drop-shadow(0 2px 2px rgba(87,104,255, 0.892)); */
}

.preview-card .card-header {
  display: flex;
}

.preview-card .card-header .card-image img {
  border-radius: 24px;
  margin-right: 20px;
  width: 92px;
  height: 92px;
}

.preview-card .card-header .header-right {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 0;
}

.preview-card .card-header .header-right .card-title {
  font-family: "Calibri";
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.01em;
  line-height: 1.2em;
  /* margin: 0; */
}

.preview-card .card-header .header-right .title-tags {
  display: flex;
  margin-bottom: 0;
}

.preview-card .card-header .header-right .title-tags span {
  padding: 6px 8px;
  background-color: #5768ff20;
  border-radius: 6px;
  color: #5768ff;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 500;
}

.preview-card .card-content {
  color: #2d4059;
  font-size: 16px;
  height: 216px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.preview-card .card-content p {
  white-space: pre-line;
  overflow-wrap: break-word;
}

.preview-card .card-tags {
  display: flex;
  padding: 15px 0;
  gap: 5px;
  /* flex-wrap: wrap; */
  color: #2d4059;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #2d405912;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.preview-card .card-tags::-webkit-scrollbar {
  display: none;
}

.preview-card .card-tags span {
  background-color: #f4f4f4;
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
}

.preview-card .card-stats {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #2d405912;
}

.preview-card .card-stats .number-of-users,
.preview-card .card-stats .diamond-size {
  display: flex;
  gap: 10px;
  align-items: center;
}

.preview-card .card-stats .number-of-users .online-indicator {
  background-color: lime;
  width: 13px;
  height: 13px;
  border-radius: 10px;
}

.preview-card .card-stats .diamond-size {
  flex-grow: 1;
  padding-left: 20px;
}

.preview-card .card-stats .number-of-users img,
.preview-card .card-stats .diamond-size img {
  width: 22px;
  height: 22px;
}

.preview-card .card-stats .more-stats .btn-vote-now {
  font-size: 16px;
  font-weight: 500;
  color: #5768ff;
  background: transparent;
  border: none;
  text-decoration: underline;
}

.preview-card .card-action {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  padding-top: 20px;
}

.preview-card .card-action .btn-card-action {
  padding: 15px 20px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
}

.preview-card .card-action .btn-card-action.join {
  background-color: #5768ff;
  color: #ffffff;
  border: none;
}

.preview-card .card-action .btn-card-action.join a {
  text-decoration: none;
  color: #ffffff;
}

.preview-card .card-action .btn-card-action.server-list {
  background-color: white;
  color: #5768ff;
  border: 1px solid #5768ff;
  text-decoration: none;
}
