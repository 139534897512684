.create-server-container {
  max-width: 1536px;
  margin: 0 auto;
  padding: 16px 64px;
}

.create-server-container h1 {
  color: #ffffff;
  font-size: 54px;
  font-weight: bold;
  text-align: center;
}

.create-server-section {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
}

@media (max-width: 767px) {
  .create-server-container {
    padding: 0px;
  }

  .create-server-section {
    grid-template-columns: 1fr;
    padding: 0 16px;
  }
}
