.dashboard-card {
  background-color: white;
  border-radius: 24px;
  max-width: 400px;
  flex-grow: 1;
  overflow: hidden;
  padding: 20px;
}

.dashboard-card .d-card-header {
  display: flex;
}

.dashboard-card .d-card-header .d-card-image img {
  border-radius: 24px;
  margin-right: 20px;
  width: 92px;
  height: 92px;
}

.dashboard-card .d-card-header .d-card-header-right {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 0;
}

.dashboard-card .d-card-header .d-card-header-right .d-card-title {
  font-family: "Calibri";
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.01em;
  /* line-height: 1.2em; */
  margin: 0;
}

.dashboard-card .d-card-header .d-card-header-right .last-bump-time {
  font-size: 14px;
  letter-spacing: -0.01em;
  font-style: italic;
}

.dashboard-card .d-card-enabled {
  margin: 10px 0;
}

.d-card-message-panel {
  min-height: 50px;
  padding: 10px;
  background-color: #5768ff10;
  border-radius: 10px;
}

.d-card-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
}

.d-card-actions a {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  background: #5768ff;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.sync-instructions-pane {
  padding: 16px 0;
  font-size: 14px;
}

.error-message-color {
  color: red;
}
