.tags-filter-section {
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 64px;
}

.tags-filter-section .tags-search-section {
  flex-grow: 1;
}

@media (max-width: 767px) {
  .tags-filter-section {
    flex-direction: column;
    justify-content: stretch;
    padding: 32px;
  }

  .tags-filter-section .tags-search-section {
    width: 100%;
    margin-bottom: 24px;
  }
}
