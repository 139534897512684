.tags-hero-container {
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 64px;
  color: #ffffff;
}

.tags-hero-container .tags-hero-left {
  display: flex;
  flex-direction: column;
}

.tags-hero-container .tags-hero-left .bread-crumbs {
  font-size: 16px;
  font-weight: 500;
}

.tags-hero-container .tags-hero-left .bread-crumbs .crumbs nav ol li {
  color: #ffffff;
}

.tags-hero-container .tags-hero-left .bread-crumbs .results {
  display: none;
}

.tags-hero-container .tags-hero-left .tags-hero-title {
  font-size: 54px;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}

.tags-hero-container .tags-hero-left .tags-hero-text {
  font-size: 16px;
  font-weight: normal;
  opacity: 0.7;
}

.tags-hero-container .tags-hero-right {
  display: block;
}

.tags-hero-container .tags-hero-right span {
  background-color: #ffffff10;
  padding: 12px 24px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .tags-hero-container .tags-hero-left {
    width: 100%;
    text-align: center;
  }

  .tags-hero-container .tags-hero-left .bread-crumbs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .tags-hero-container .tags-hero-left .bread-crumbs .results {
    display: block;
  }

  .tags-hero-container .tags-hero-left .tags-hero-title {
    /* font-size: 32px; */
    margin: 16px 0;
  }

  .tags-hero-container .tags-hero-right {
    display: none;
  }
}
