.view-server-body {
  display: flex;
  margin: 0 auto;
  gap: 40px;
  padding: 0 64px;
}

.view-server-body-left-pane {
  max-width: 512px;
}

.view-server-body-left-pane,
.view-server-body-right-pane {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.view-server-body-left-pane .server-image img {
  border-radius: 20px;
  width: 512px;
  height: 512px;
}

.view-server-body-left-pane .about-server {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px 40px;
  white-space: pre-line;
}

.view-server-body-left-pane .player-history {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px 40px;
  overflow: auto;
}

.view-server-body-right-pane .server-vote {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px 40px;
  text-align: center;
}

.view-server-body-right-pane .server-vote .btn-server-vote {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  padding: 20px 25px;
  width: 100%;
  background: #5768ff;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.view-server-body-right-pane .server-vote .btn-server-vote.disabled {
  pointer-events: auto;
  background: grey;
}

.view-server-body-right-pane .server-informations {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px 40px;
}

.view-server-body-right-pane .server-informations .info-body {
  display: flex;
  flex-direction: column;
}

.view-server-body-right-pane .server-informations .info-body .info-row {
  display: grid;
  grid-template-columns: 0.5fr 2fr;
  align-items: center;
}

.view-server-body-right-pane .server-informations .info-body .info-row h4,
.view-server-body-right-pane .server-informations .info-body .info-row p {
  margin: 0;
  line-height: 2.2em;
}

.view-server-body-right-pane .server-informations .server-tags {
  display: flex;
  padding: 15px 0;
  gap: 5px;
  flex-wrap: wrap;
  color: #2d4059;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #2d405912;
}

.view-server-body-right-pane .server-informations .server-tags span {
  background-color: #f4f4f4;
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
}

.view-server-body-right-pane .server-informations .server-tags span a {
  text-decoration: none;
  color: black;
}

.view-server-body-right-pane .server-informations .info-actions {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}

.view-server-body-right-pane .server-informations .info-actions a {
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  padding: 16px 32px;
  border-radius: 12px;
  cursor: pointer;
  border: none;
}

.view-server-body-right-pane
  .server-informations
  .info-actions
  .btn-join-discord {
  color: #ffffff;
  background: #5768ff;
}

.view-server-body-right-pane
  .server-informations
  .info-actions
  .btn-website-url {
  color: #5768ff;
  background: #ffffff;
  border: 1px solid #5768ff;
}

.view-server-body-right-pane .server-top-voters {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 30px 40px;
}

.view-server-body-right-pane .server-top-voters .top-voters-body {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.view-server-body-right-pane
  .server-top-voters
  .top-voters-body
  .voter-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding-bottom: 0.4em;
  border-bottom: 1px solid #2d405910;
}

.view-server-body-right-pane
  .server-top-voters
  .top-voters-body
  .voter-details:last-child {
  border-bottom: none;
}

.view-server-body-right-pane
  .server-top-voters
  .top-voters-body
  .voter-details
  img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.view-server-body-right-pane
  .server-top-voters
  .top-voters-body
  .voter-details
  .name {
  flex-grow: 1;
  font-weight: 500;
}

.view-server-body-right-pane
  .server-top-voters
  .top-voters-body
  .voter-details
  .votes {
  font-weight: 500;
}

@media (max-width: 767px) {
  .view-server-body {
    flex-direction: column;
    padding: 0 16px;
  }

  .view-server-body-left-pane .server-image img {
    width: 100%;
    height: auto;
  }

  .view-server-body-right-pane .server-informations .info-actions {
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .view-server-body-left-pane .player-history {
    display: none;
  }
}
