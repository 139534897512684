.card {
  background-color: white;
  border-radius: 24px;
  overflow: hidden;
  padding: 20px;
  max-height: 532.5px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.card .card-header {
  display: flex;
}

.card .card-header .card-image img {
  border-radius: 24px;
  margin-right: 20px;
  width: 92px;
  height: 92px;
}

.card .card-header .header-right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.card .card-header .header-right .card-title {
  display: flex;
  align-items: center;
  font-family: "Calibri";
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.01em;
  line-height: 1.2em;
  margin: 0;
  flex-grow: 1;
}

.card .card-header .header-right .title-tags {
  display: flex;
  margin-bottom: 3px;
  gap: 10px;
}

.card .card-header .header-right .title-tags span {
  padding: 6px 8px;
  background-color: #5768ff20;
  border-radius: 6px;
  color: #5768ff;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 500;
}

.card .card-header .header-right .title-tags .premium {
  padding: 6px 8px;
  background-color: rgba(184, 132, 91, 0.15);
  border-radius: 6px;
  color: #9c6b44;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 1000;
}

.card .card-content {
  color: #2d4059;
  font-size: 16px;
  height: 216px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (max-width: 767px) {
  .card {
    max-width: none;
  }
}
