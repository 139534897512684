.server-preview-container {
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.preview-container-title {
  font-size: 24px;
  padding: 20px 0 0 20px;
  font-weight: 500;
  color: #000000;
  margin: 0;
}
