.seo {
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 64px;
  font-size: 18px;
  color: #ffffff;
}
