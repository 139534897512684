/* Pagination Styles */
.pagination-container {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;
  gap: 8px;
}

.pagination-item {
  padding: 12px 20px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  background-color: #363b48;
  border-radius: 12px;
  min-width: 32px;
}

.pagination-item.dot:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-item:hover {
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
}

.pagination-item.selected {
  background-color: #5768ff;
}

.pagination-item.active {
  background-color: #5768ff;
}

.pagination-item .arrow::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid #ffffff;
  border-top: 0.12em solid #ffffff;
}

.pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.pagination-item .arrow.right {
  transform: rotate(45deg);
}

.pagination-item .arrow:hover {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid #000000;
  border-top: 0.12em solid #000000;
}

.pagination-item.disabled {
  pointer-events: none;
}

.pagination-item.disabled .arrow::before {
  border-right: 0.12em solid #ffffff;
  border-top: 0.12em solid #ffffff;
}

.pagination-item.disabled .arrow:hover {
  background-color: transparent;
  cursor: default;
}
