.server-card .card-content p {
  white-space: pre-line;
  overflow-wrap: break-word;
}

.server-card .card-tags {
  display: flex;
  padding: 15px 0;
  gap: 5px;
  color: #2d4059;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #2d405912;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.server-card .card-tags::-webkit-scrollbar {
  display: none;
}

.server-card .card-tags span {
  background-color: #f4f4f4;
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
}

.server-card .card-tags span a {
  text-decoration: none;
  color: black;
}

.server-card .card-stats {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #2d405912;
}

.server-card .card-stats .number-of-users,
.server-card .card-stats .diamond-size {
  display: flex;
  gap: 10px;
  align-items: center;
}

.server-card .card-stats .number-of-users .online-indicator {
  background-color: lime;
  width: 13px;
  height: 13px;
  border-radius: 10px;
}

.server-card .card-stats .diamond-size {
  flex-grow: 1;
  padding-left: 20px;
}

.server-card .card-stats .number-of-users img,
.server-card .card-stats .diamond-size img {
  width: 22px;
  height: 22px;
}

.server-card .card-stats .more-stats .btn-vote-now {
  font-size: 16px;
  font-weight: 500;
  color: #5768ff;
  background: transparent;
  border: none;
  text-decoration: underline;
}

.server-card .card-action {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  padding-top: 20px;
}

.server-card .card-action .btn-card-action {
  padding: 15px 20px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
}

.server-card .card-action .btn-card-action.join {
  background-color: #5768ff;
  color: #ffffff;
  border: none;
}

.server-card .card-action .btn-card-action.join a {
  text-decoration: none;
  color: #ffffff;
}

.server-card .card-action .btn-card-action.server-list {
  background-color: white;
  color: #5768ff;
  border: 1px solid #5768ff;
  text-decoration: none;
}
