.card-container {
  max-width: 1536px;
  width: 100%;
  margin: auto;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
}

.card-container .card-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  /* mix-blend-mode: normal; */
  padding: 0 64px;
  margin: 0 auto;
}

.card-container .card-items .no-cards {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.card-container .pagination {
  padding: 16px 0 32px 0;
}

@media (max-width: 1280px) {
  .card-container .card-items {
    padding: 0 32px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .card-container .card-items {
    padding: 0 32px;
    grid-template-columns: 1fr;
  }
}
