.commands-pane {
  max-width: 1536px;
  width: 100%;
  margin: auto;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
}

.commands-header {
  max-width: 1420px;
  padding: 0 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.commands-name {
  max-width: 720px;
  color: #ffffff;
  width: 100%;
  margin: auto;
  border-bottom: 1pt solid white;
}
.commands-description {
  max-width: 720px;
  color: #ffffff;
  width: 100%;
  margin: auto;
}
