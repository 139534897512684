footer {
  margin-top: 64px;
  width: 100%;
  background-color: #1e2431;
  color: #fff;
}

.footer-container {
  max-width: 1536px;
  margin: 0 auto;
}

.main-hr,
.copyright-hr {
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.footer-top {
  padding: 48px 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-top .left .footer-logo-image {
  height: 35px;
}

.footer-top .right {
  display: flex;
  gap: 6em;
}

.footer-top .right ul {
  padding-left: 0;
}

.footer-top .right ul li {
  list-style: none;
  line-height: 2em;
  font-size: 16px;
}

.footer-top .right ul li a {
  text-decoration: none;
  color: #ffffff;
}

.footer-bottom {
  padding: 32px 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom .copyright {
  color: rgba(255, 255, 255, 0.4);
  font-size: 10px;
}

.copyright-hr {
  opacity: 0;
}

.footer-bottom .social-media {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.footer-bottom .social-media span svg {
  width: 16px;
  height: 16px;
}

.footer-bottom .social-media span {
  background-color: #292f3c;
  padding: 13px 17px;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .main-hr {
    opacity: 0;
  }

  .footer-top {
    padding: 16px;
    flex-direction: column;
    align-items: stretch;
  }

  .footer-top .left {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
  }

  .footer-top .footer-logo-image {
    margin: 0 auto;
  }

  .footer-top .right {
    display: flex;
    gap: 0;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
  }

  .footer-top .right ul {
    margin: 0;
  }

  .footer-bottom {
    padding: 16px;
    flex-direction: column-reverse;
  }

  .footer-bottom .social-media {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 3px 0px;
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
}
