.filter-container {
  display: flex;
  align-items: center;
  gap: 25px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.filter-container .filter {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 25px;
  border-right: 2px solid #ffffff30;
}

.filter-container .join-discord {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  text-decoration: none;
  color: #ffffff;
}

.filter-container .join-discord .join-discord-image {
  display: none;
}

@media (max-width: 767px) {
  .filter-container {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .filter-container .filter {
    border: none;
  }
}
