.main-pane {
  max-width: 1536px;
  width: 100%;
  margin: auto;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
}

.view-server-header {
  color: #ffffff;
  padding: 0 64px;
  text-align: center;
}

.view-server-header h1 {
  font-size: 54px;
  font-weight: bold;
  margin-top: 0;
}

@media (max-width: 767px) {
  .view-server-header {
    padding: 0;
    text-align: center;
  }
}
