.leaderboard-container {
  max-width: 1536px;
  margin: 0 auto;
  padding: 0 64px;
}

.leader .runner-ups {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-items: space-evenly;
}

.leader-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;
  /* mix-blend-mode: normal; */
}

.leader-card-container {
  position: relative;
}

.server-rank {
  top: -20px;
  left: -20px;
  position: absolute;
  height: 64px;
  width: 64px;
  line-height: 64px;
  background-color: white;
  text-align: center;
  border-radius: 50%;
  border-style: ridge;
  border: 1px solid black;
}

.podium {
  appearance: none;
  cursor: pointer;
  background: url(/public/down-arrow/down-arrow-select.png) no-repeat right
    #ffffff;
  background-position-x: calc(100% - 20px);
}

.leader-container {
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 32px;
}

.leader-rank {
  display: flex;
  justify-content: center;
  width: 50px;
}

.leader-pill {
  flex: 1;
}

.leader-pill-avatar {
  margin-right: 24px;
  /* flex: 0.25; */
}

.leader-pill-name {
  font-weight: normal;
  flex: 1;
}

.leader-pill-points {
  font-weight: normal;
  margin-right: 48px;
}

@media (max-width: 1280px) {
  .leaderboard-container {
    padding: 0 32px;
  }
  .leader-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .leader-card-container {
    grid-column: span 2;
  }
}

@media (max-width: 767px) {
  .leader-grid {
    grid-template-columns: minmax(0, 1fr);
  }
}

@media (max-width: 600px) {
  .leader-pill-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .leader-pill-points {
    margin-right: 16px;
  }
}
