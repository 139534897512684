.header {
  max-width: 1536px;
  margin: 0 auto;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 35px;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  text-decoration: none;
}

.main-nav ul {
  display: flex;
  gap: 2em;
}

.main-nav ul li a {
  color: #ffffff;
}

.main-nav ul li a:hover {
  color: #5768ff;
}

.header-btns {
  display: flex;
  align-items: center;
  gap: 2em;
}

/* The dropdown container */
.header-btns .dropdown {
  color: #ffffff;
  overflow: hidden;
  display: flex;
  gap: 0.5em;
  align-items: center;
  cursor: pointer;
}

.header-btns .dropdown .menu-profile {
  color: #ffffff;
  border-radius: 20px;
}

/* Dropdown content (hidden by default) */
.header-btns .dropdown .dropdown-content {
  display: none;
  position: absolute;
  top: 70px;
  right: 30px;
  background-color: #ffffff10;
  min-width: 150px;
  /* box-shadow: 0px 8px 16px 0px rgba(255, 255, 255,0.1); */
  z-index: 1;
}

.link-dropdown-content {
  background-color: #ffffff10;
  min-width: 150px;
  /* box-shadow: 0px 8px 16px 0px rgba(255, 255, 255,0.1); */
  z-index: 1;
  font-weight: bold;
}

/* Links inside the dropdown */
.header-btns .dropdown .dropdown-content a,
.link-dropdown-content .sublink {
  float: none;
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Add a grey background color to dropdown links on hover */
.header-btns .dropdown .dropdown-content a:hover,
.link-dropdown-content .sublink:hover {
  color: #5768ff;
  cursor: pointer;
}

/* Show the dropdown menu on click */
.header-btns .dropdown .show-dropdown-content {
  display: block;
}

.sign-in {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background: transparent;
  border: none;
}

.sign-in a {
  text-decoration: none;
  color: white;
}

.sign-in a:hover {
  color: #5768ff;
}

.btn-add-server {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  background: #5768ff;
  box-shadow: 0px 5px 30px rgba(66, 130, 255, 0.2);
  border-radius: 30px;
  cursor: pointer;
  border: none;
}

.btn-add-server:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
}

.hamburger,
.mobile-menu,
.mobile-profile {
  display: none;
}

.link-popper {
  top: 20px !important;
}

@media (max-width: 768px) {
  .main-nav,
  .header-btns {
    display: none;
  }

  .mobile-profile {
    display: block;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    border-radius: 20px;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: all 0.25s;
    position: relative;
    background: transparent;
    border: none;
  }

  .hamburger-top,
  .hamburger-middle,
  .hamburger-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 2px;
    background: #ffffff;
    transform: rotate(0);
    transition: all 0.5s;
  }

  .hamburger-middle {
    transform: translateY(7px);
  }

  .hamburger-bottom {
    transform: translateY(14px);
  }

  .open-hamburger {
    transform: rotate(90deg);
    transform: translateY(0px);
  }

  .open-hamburger .hamburger-top {
    transform: rotate(45deg) translateY(6px) translate(6px);
  }

  .open-hamburger .hamburger-middle {
    display: none;
  }

  .open-hamburger .hamburger-bottom {
    transform: rotate(-45deg) translateY(6px) translate(-6px);
  }

  .mobile-menu {
    display: block;
    position: relative;
  }

  .mobile-menu-closed {
    display: none;
  }

  .mobile-menu-open {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    padding: 32px 0;
    margin-top: 24px;
    gap: 24px;
    font-weight: bold;
    background-color: #ffffff;
    left: 24px;
    right: 24px;
    filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
      drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
    z-index: 10;
  }

  .mobile-menu-open li a {
    color: #000000;
  }
}
