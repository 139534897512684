.server-form-container {
  padding: 30px;
  border-radius: 20px;
  background-color: #ffffff;
}

.server-form {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.server-form .form-control {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
}

.server-form h3 {
  font-size: 24px;
  font-weight: 500;
}

.server-form .form-control label,
.tags-form-control label {
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.server-form .form-control.error label {
  color: red;
}

.tags-form-control label {
  margin-bottom: 10px;
}

.server-form .form-control input,
.server-form .form-control textarea,
.server-form .form-control select {
  padding: 15px 20px;
  border: 1px solid #2d405940;
  border-radius: 12px;
  font-size: 16px;
  font-weight: normal;
}

.server-form .form-control .create-server-select {
  appearance: none;
  cursor: pointer;
  background: url(/public/down-arrow/down-arrow-select.png) no-repeat right
    #ffffff;
  background-position-x: calc(100% - 20px);
}

.server-form .form-control .help-block,
.server-form .tags-form-control p {
  font-size: 13px;
  font-style: italic;
}

.server-form .form-control.error input,
.server-form .form-control.error textarea,
.server-form .form-control.error select {
  border: 1px solid red;
}

.server-form .form-error {
  font-size: 16px;
  font-weight: normal;
  font-style: italic;
  color: red;
}

.server-form .btn-form-control .btn-save-server {
  display: inline;
  min-width: none;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  padding: 15px 20px;
  background: #5768ff;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  margin-top: 5px;
}
