.dashboard-pane {
  max-width: 1536px;
  width: 100%;
  margin: auto;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
}

.dashboard-header {
  max-width: 1420px;
  padding: 0 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.dashboard-header-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.btn-dashboard-header {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  padding: 8px 16px;
  background: #5768ff;
  border-radius: 8px;
  cursor: pointer;
  border: none;
}

.dashboard-cards {
  max-width: 1536px;
  width: 100%;
  margin: auto;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
}

.dashboard-card-items {
  /* display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  /* mix-blend-mode: normal; */
  padding: 0 64px;
  margin: auto;
}

.dashboard-card-items .no-cards {
  color: #ffffff;
}

@media (max-width: 1024px) {
  .dashboard-card-items {
    padding: 0 32px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .dashboard-header {
    flex-direction: column;
  }
  .dashboard-card-items {
    padding: 0 16px;
    grid-template-columns: 1fr;
  }
}
